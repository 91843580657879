import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable, computed, effect, inject, signal } from "@angular/core"
import { Observable, finalize, switchMap, throwError } from "rxjs"
import { B2bLoginService } from "../core/b2b-login.service"
import { ConfigService } from "../core/config.service"
import { CartDTO, ItemForPurchaseRequestDTO, ResponseBody } from "../models/Entities"


@Injectable({
  providedIn: "root"
})
export class B2bCartService {
  private config = inject(ConfigService);
  private get purchaseUrl(): string {
    return this.config.getPurchasesUrl() + "/api/v2/items-for-purchase";
  }
  private get cartUrl(): string {
    return this.config.getPurchasesUrl() + "/api/v2/carts";
  }

  cartArticles = signal<any[]>([])
  isCartEmpty = computed<boolean>(() => this.cartArticles().length === 0)
  numberOfArticles = computed<number>(() => this.cartArticles().reduce((accumulator, article) => accumulator + article.quantity, 0))
  totalPrice = computed<number>(() => this.cartArticles().reduce((accumulator, article) => accumulator + article.price * article.quantity, 0))
  isLoadingCart = false

  constructor(private http: HttpClient, private loginService: B2bLoginService) {
    this.isLoadingCart = true
    const userID = this.loginService.getUserId()

    // Recupero info iniziali su quantità da far vedere nella header (icona carrellino)
    this.getCartInformation(userID)
      .pipe(
        switchMap((response) => {
          const cartId = response?.payload?.uuid
          if (cartId) {
            return this.loadSpecificCartArticles(cartId)
          } else {
            return throwError(() => new Error("204"))
          }
        }),
        finalize(() => (this.isLoadingCart = false))
      )
      .subscribe({
        next: (response) => {
          this.cartArticles.set(response.payload)
        },
        error: (err) => {
          console.error(err)
          this.cartArticles.set([])
        }
      })

    effect(() => {
      const currentValue = this.cartArticles()
    })

  }

  getCartInformation(userId: string): Observable<ResponseBody<CartDTO>> {
    return this.http.get<ResponseBody<CartDTO>>(`${this.cartUrl}/user/${userId}`)
  }
  // sezione BE

  // loadCart() {
  //   return this.http.get<Object>(`${this.env.purchasesUrl}:${this.env.acquistiPort}`)
  // }

  /**
   * retrieve items of the current cart
   *
   * @remarks this method refers to the "getItemsOfCurrentCart()" method in the "ItemForPurchaseAPI" interface
   * @throws {ResponseBody} if the backend code throws an error it will be displayed inside of "status" inside the "ResponseBody"
   * @returns ResponseBody with a payload that contains the object coming from the backend
   * @example
   * this.loadCurrentCartArticles().subscribe(response => {
   *   console.log(response);
   * }, error => {
   *   console.error('Error retrieving current articles');
   * });
   */
  loadCurrentCartArticles(): Observable<ResponseBody<any>> {
    const options = { params: new HttpParams().set("user_id", this.loginService.getUserId()) }
    return this.http.get<ResponseBody<any>>(this.purchaseUrl, options)
  }

  /**
  * Retrieve items of a specific cart
  * @remarks this method refers to the "getItemsByCartId()" method in the "ItemForPurchaseAPI" interface
  * @throws {ResponseBody} if the backend code throws an error it will be displayed inside of "status" inside the "ResponseBody"
  
  * @returns ResponseBody with a payload that contains the object coming from the backend
  * @example
  * this.loadSpecificCartArticles(cartId).subscribe(response => {
  *   console.log('Articoli nel carrello:', response.payload);
  * }, error => {
  *   console.error('Error retrieving articles');
  * });
  */
  loadSpecificCartArticles(cartId: string): Observable<ResponseBody<any>> {
    return this.http.get<ResponseBody<any>>(`${this.purchaseUrl}/cart/${cartId}`)
  }

  /**
   * Get the total quantity of the current cart
   * @remarks this method refers to the "getQuantityForCurrentCartByUserId()" method in the "ItemForPurchaseAPI" interface
   * @throws {ResponseBody} if the backend code throws an error it will be displayed inside of "status" inside the "ResponseBody"
   * @returns ResponseBody with a payload that contains the object coming from the backend
   * @example
   * this.currentCartQuantity().subscribe(response => {
   *   console.log('Total quantity:', response.payload);
   * }, error => {
   *   console.error('Error retrieving the total quantity');
   * });
   */
  currentCartQuantity(): Observable<ResponseBody<any>> {
    const options = { params: new HttpParams().set("user_id", this.loginService.getUserId()) }
    return this.http.get<ResponseBody<any>>(`${this.purchaseUrl}/quantity`, options)
  }

  /**
   * Add one or more items to the current cart.
   *
   * @remarks This method refers to the "addArticleToCart()" method in the "ItemForPurchaseAPI" interface.
   * @throws {ResponseBody} If the backend code throws an error, it will be displayed inside of "status" inside the "ResponseBody".
   * @param articles An array of `ItemForPurchaseRequestDTO` objects representing the articles to add to the cart.
   * @returns ResponseBody
   * @example
   * this.addArticleToCartBE(articles).subscribe(response => {
   *   console.log('Add to cart response:', response);
   * }, error => {
   *   console.error('Error adding articles to the cart');
   * });
   */
  addArticleToCartBE(article: ItemForPurchaseRequestDTO) {
    const options = { params: new HttpParams().set("user_id", this.loginService.getUserId()) }
    return this.http.post<ResponseBody<any>>(this.purchaseUrl, article, options)
  }

  addSingleArticleToCartBE(article: any) {
    const options = { params: new HttpParams().set("user_id", this.loginService.getUserId()) }
    return this.http.post<ResponseBody<any>>(this.purchaseUrl, article, options)
  }

  /**
   * Updates an item's quantity in the current cart.
   *
   * @remarks This method refers to the "updateArticleQuantity()" method in the "ItemForPurchaseAPI" interface.
   * @throws {ResponseBody} If the backend code throws an error, it will be displayed inside of "status" inside the "ResponseBody".
   * @param article An `ItemForPurchaseRequestDTO` object representing the article whose quantity is to be updated.
   * @param quantity The new quantity for the article.
   * @returns ResponseBody
   * @example
   * this.updateArticleQuantityBE(article, quantity).subscribe(response => {
   *   console.log('Update quantity response:', response);
   * }, error => {
   *   console.error('Error updating article quantity');
   * });
   */
  updateArticleQuantityBE(itemId: string, quantity: number) {
    let params = new HttpParams().set("user_id", this.loginService.getUserId()).set("quantity", quantity)
    return this.http.put<ResponseBody<any>>(`${this.purchaseUrl}/item/${itemId}/quantity`, {}, { params })
  }

  /**
   * Deletes an item from the current cart.
   *
   * @remarks This method refers to the "deleteArticleFromCart()" method in the "ItemForPurchaseAPI" interface.
   * @throws {ResponseBody} If the backend code throws an error, it will be displayed inside of "status" inside the "ResponseBody".
   * @param article An `ItemForPurchaseRequestDTO` object representing the article to delete from the cart.
   * @returns ResponseBody
   * @example
   * this.deleteArticleFromCartBE(article).subscribe(response => {
   *   console.log('Delete from cart response:', response);
   * }, error => {
   *   console.error('Error deleting article from the cart');
   * });
   */
  deleteArticleFromCartBE(itemId: string) {
    const options = { params: new HttpParams().set("user_id", this.loginService.getUserId()) }
    return this.http.delete<ResponseBody<any>>(`${this.purchaseUrl}/item/${itemId}`, options)
  }

  addArticleToCart(newArticle: Article) {
    this.cartArticles.update((currentItems) => [...currentItems, newArticle])
  }

  removeArticleFromCart(articleToRemove: Article) {
    this.cartArticles.update((currentItems) => currentItems.filter((item) => item.uuid !== articleToRemove.uuid))
  }
}

export interface Article {
  uuid: string
  itemId: string
  quantity: number
  cartId: string
  price: number
}
